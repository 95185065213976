import React, { useState, useRef, useEffect } from 'react';
import Rollbar from 'rollbar';
import axios from 'axios';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Box, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { animated, useSpring } from '@react-spring/web';
import { BASE_URL, rollbarConfig } from '../constants';

import '../assets/css/typing.css';
import FileComponent from './utils/FileComponent';
import { searchWordInTypes } from '../utils';

const rollbar = new Rollbar(rollbarConfig);

const useStyles = makeStyles(_ => ({
	messageContainer: {
		textAlign: 'left',
		display: 'flex',
		flexFlow: 'column wrap'
	},
	agentMessageContainer: {
		alignItems: 'flex-start',
		alignContent: 'flex-start'
	},
	userMessageContainer: {
		alignItems: 'flex-end',
		alignContent: 'flex-end'
	},
	agentMessage: {
		color: '#B59048',
		marginLeft: '7px'
	},
	userMessage: {
		color: '#3F2A1C',
		marginRight: '7px'
	},
	chatText: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '400'
	},
	messageSender: {
		height: '14px',
		fontSize: '10px',
		lineHeight: '130%',
		marginBottom: '5px'
	},
	bubbleContainer: {
		display: 'flex',
		position: 'relative',
		alignItems: 'center'
	},
	messageBubble: {
		maxWidth: '268px',
		minWidth: '10px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		padding: '1px 12px',
		borderRadius: '12px',
		order: '0',
		alignSelf: 'stretch',
		flexGrow: '1'
	},
	agentMessageBubble: {
		background: '#F1E9DA'
	},
	userMessageBubble: {
		background: '#A6823C'
	},

	message: {
		fontSize: '15px',
		lineHeight: 'normal',
		letterSpacing: '-0.408px',
		fontFeatureSettings: "'case' on",
		color: '#FFFFFF',
		wordBreak: 'break-word',
		whiteSpace: 'pre-line'
		// hyphens: 'auto'
	},

	businessMessage: {
		color: '#000000',
		fontSize: '15px',
		lineHeight: '24px',
		fontWeight: 450, /* Regular */


		'& > p': {
			margin: 0,
			
		},
		'& > ol': {
			margin: '0.4em 2 -0.625em',
			paddingLeft: '1.5em',
			lineHeight: '1.25em'
		},
		'& > h1': {
			fontSize: '20px',
			lineHeight: '32px',
			fontWeight: 600
		},
		'& > h2, & > h3, & > h4, & > h5, & > h6': {
			fontSize: '18px',
			lineHeight: '28px',
			fontWeight: 600,
			margin: '5px',
			paddingBottom: '4px'
		}
	},

	messageTime: {
		height: '16px',
		fontSize: '12px',
		lineHeight: '130%',
		color: '#7A869A',
		marginTop: '4px',
		marginBottom: '4px'
	},
	agentMessageTime: {
		marginLeft: '7px',
		visibility: 'hidden',

		'&:hover': {
			visibility: 'visible'
		}
	},
	userMessageTime: {
		marginRight: '7px',
		visibility: 'hidden',

		'&:hover': {
			visibility: 'visible'
		}
	},
	marginBottom: {
		marginBottom: '8px'
	},
	imageFile: {
		width: '100%',
		maxWidth: '200px',
		maxHeight: '200px',
		objectFit: 'contain',
		marginLeft: 'auto',
		borderRadius: '8px'
	},
	userFileUpd: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '10px'
	}
}));

const LOCAL_IMG_TYPES = [
	'image/gif',
	'image/jpeg',
	'image/jpg',
	'image/png',
	'svg',
	'gif',
	'jpeg',
	'jpg',
	'png',
	'svg'
];
const LOCAL_FILE_TYPES = [
	'pdf',
	'xls',
	'xlsx',
	'doc',
	'docx',
	'application/pdf',
	'application/msword',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export default function ChatMessage({ companyConfig, message, isTimeEnabled, playground, appID, conversationId }) {
	const classes = useStyles();
	const styles = useSpring({
		from: {
			scale: 0,
			transition: message.type === 'business' ? '110ms' : '100ms',
			transformOrigin: message.type === 'business' ? 'bottom left' : 'bottom right',
			willChange: 'transform',
			animationFillMode: 'forwards'
		},
		to: {
			scale: 1
		}
	});
	const msgReg = useRef(null);
	const [like, setLike] = useState(message.thumbsUpDown);
	const [widthBlock, setWidthBlock] = useState(null);

	const sendThumbsMsg = async (messageId = '', rate = null) => {
		try {
			const url = `https://${BASE_URL}/api/v1/${appID}/conversation/${conversationId}/message/rate`;
			setLike(rate);
			await axios.post(url, { messageId, rate });
		} catch (e) {
			rollbar.error(e);
			console.error(e);
		}
	};

	useEffect(() => {
		if (!_.isEmpty(message)) {
			setTimeout(() => {
				setWidthBlock(msgReg?.current?.getBoundingClientRect()?.width);
			}, 700);
		}
	}, [msgReg?.current?.getBoundingClientRect()?.width, message]);

	switch (message.type) {
		case 'business':
			return (
				<>
					<animated.div
						className={`${classes.agentMessageContainer} ${classes.messageContainer} ${
							!isTimeEnabled ? classes.marginBottom : ''
						}`}
						style={styles}
					>
						{/* uncomment this to enable message sender header */}
						{/* <p
                className={`${classes.chatText} ${classes.messageSender} ${classes.agentMessage}`}
              >
                {message.senderName.toUpperCase()}
              </p> */}
						<div className={classes.bubbleContainer} ref={msgReg}>
							<span style={{ alignSelf: 'flex-start' }}>
								{' '}
								<img
									src={companyConfig.botIconUrl ? companyConfig.botIconUrl : companyConfig.chatLogo}
									alt="logo"
									style={{
										maxWidth: '36px',
										maxHeight: '36px',
										overflow: 'hidden',
										borderRadius: '36px',
										marginRight: '10px',
										alignSelf: 'flex-start'
									}}
								/>
							</span>

							<div
								className={`${classes.messageBubble} ${classes.agentMessageBubble}`}
								style={{
									maxWidth: '468px',
									background: companyConfig.agentBubbleColor,
									borderRadius: companyConfig?.messageBubbleBorderRadius || "12px"
								}}
							>
								<p
									className={`${classes.chatText} ${classes.message} ${classes.businessMessage}`}
									dangerouslySetInnerHTML={{ __html: message.value.replace(/\\/g, '"') }}
									style={{ color: companyConfig.agentTextColor }}
								/>
							</div>
						</div>
						<Box display="flex" justifyContent="flex-end" marginLeft="auto" alignItems="baseline" position="relative" bottom="8px" width={widthBlock}>
							{isTimeEnabled && (
								<p className={`${classes.chatText} ${classes.messageTime} ${classes.agentMessageTime}`}>
									{message.time}
								</p>
							)}
							{message.votingEnabled && (<>
								<p
								style={{
									fontWeight: 450,
									lineHeight: '24px',
									fontSize: '14px',
									fontFamily: 'Inter',
									color: '#7E8B99',
									margin: "0 10px",
									position: "relative",
									right: 0 // Positioning it at the right side
								}}
							>
								Was this helpful?
							</p>
								<Box
									display="flex"
									visibility={msgReg?.current?.getBoundingClientRect()?.width === widthBlock ? 'visible' : 'hidden'}
									sx={{
										borderRadius: '30px',
										border: '1px solid white',
										backgroundColor: 'white',
										padding: '4px 8px',
										gap: '4px',
										boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
									}}
								>
									

									<IconButton
										size="small"
										onClick={() => {
											if (like === 'UP') {
												sendThumbsMsg(message.id, null);
											} else {
												sendThumbsMsg(message.id, 'UP');
											}
										}}
									>
										<ThumbUpIcon
											fontSize="small"
											color={like === 'UP' ? 'success' : 'inherit'}
											sx={{ fontSize: '14px', color: like === 'UP' ? 'rgb(54, 179, 126)' : 'inherit' }}
										/>
									</IconButton>
									<IconButton
										size="small"
										onClick={() => {
											if (like === 'DOWN') {
												sendThumbsMsg(message.id, null);
											} else {
												sendThumbsMsg(message.id, 'DOWN');
											}
										}}
									>
										<ThumbDownIcon
											fontSize="small"
											color={like === 'DOWN' ? 'error' : 'inherit'}
											sx={{ fontSize: '14px' }}
										/>
									</IconButton>
								</Box>
								</>
							)}
						</Box>
					</animated.div>
				</>
			);
		case 'user':
		default:
			return (
				<animated.div
					className={`${classes.userMessageContainer} ${classes.messageContainer} ${
						!isTimeEnabled ? classes.marginBottom : ''
					}`}
				>
					{/* uncomment this to enable message sender header */}
					{/* <p
            className={`${classes.chatText} ${classes.messageSender} ${classes.userMessage}`}
          >
            {message.senderName.toUpperCase()}
          </p> */}
					<div className={classes.bubbleContainer}>
						<div
							className={`
                ${classes.messageBubble} 
                ${classes.userMessageBubble} 
                ${message.messageType === 'attachment' && classes.userFileUpd}`}
							style={{
								maxWidth: playground && '468px',
								background: companyConfig.userBubbleColor
							}}
						>
							{message.messageType === 'attachment' &&
								searchWordInTypes(message?.encodedAttachment, LOCAL_IMG_TYPES) && (
									<img src={message.encodedAttachment} alt="attach" className={classes.imageFile} />
								)}
							{message.messageType === 'attachment' &&
								searchWordInTypes(message?.encodedAttachment, LOCAL_FILE_TYPES) && (
									<FileComponent encodedAttachment={message?.encodedAttachment} elements={LOCAL_FILE_TYPES} />
								)}
							<p className={`${classes.chatText} ${classes.message}`} style={{ color: companyConfig.userTextColor }}>
								{message.value}
							</p>
						</div>
					</div>
					{isTimeEnabled && (
						<p className={`${classes.chatText} ${classes.messageTime} ${classes.userMessageTime}`}>{message.time}</p>
					)}
				</animated.div>
			);
	}
}
