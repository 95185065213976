import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import WidgetButton from './WidgetButton';
import { CHAT_API_URL, GATEWAY_PLAYGROUND_API_URL } from '../constants';
import ChatContainer from './ChatContainer';
import '../assets/css/iframe.css';



import RBLogo from '../assets/img/reser-logo.svg';

const useStyles = makeStyles(_ => ({
	chatWidgetContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		height: '100vh'
	}
}));

let companyConfig = {
	imgSrc: null,
	expImgSrc: null,
	defaultColor: '#0065FF',
	secondaryColor: '#091E42',
	backgroundColor: '#0065FF',
	agentMessageBackgroundColor: '#DEEBFF',
	userMessageBackgroundColor: '#448AF7',
	agentMessageTextColor: '#000',
	userMessageTextColor: '#fff',
	backgroundColorGradient: 'linear-gradient(92.28deg, #0065FF 0.77%, #0747A6 99.3%)'
};

const CHAT_DEFAULT_STATE = {
	...companyConfig,
	name: '',
	position: 'left',
	brandColor: '#1C6DF0',
	chatBackgroundColor: 'white',
	chatOpenCloseColor: '#1C6DF0',
	agentBubbleColor: '#deebff',
	userBubbleColor: '#448af7',
	agentTextColor: 'black',
	userTextColor: 'white'
};

export default function ChatWidgetContainer({ onCustomHandOff }) {
	const [isWidgetExpanded, setIsWidgetExpanded] = useState(false);
	const [styleChat, setStyleChat] = useState(null);
	const [isChatWidgetVisible, setChatWidgetVisible] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [containerHeight, updateContainerHeight] = useState(0);
	const classes = useStyles();
	const urlParams = new URLSearchParams(window.location.search);
	// based on the appId we can render different set of styles and assets to customize the chat widget
	const appID = urlParams.get('app_id'); //  || "49c33cf3-6213-4830-97ed-90196608661c"
	const playground = urlParams.get('playground');
	const appPosition = urlParams.get('app_position') || 'left';
	const hideHeader = urlParams.get('hide_header') === 'true';
	const hideFooter = urlParams.get('hide_footer') === 'true';

	const demoPage = urlParams.get('demo_page');
	const flowVersionUuid = urlParams.get('flow_version_uuid') || urlParams.get('flowVersionUuid') || null;
	const extension = JSON.parse(urlParams.get('extension')) || false;

	useEffect(() => {
		const handleResize = (event) => {
			if (event.data.type === 'window-resize') {
				setIsMobile(event.data.width < 768);
				updateContainerHeight(event.data.height);
			}
		};

		window.addEventListener('message', handleResize);

		return () => {
			window.removeEventListener('message', handleResize);
		};
	}, []);


	useEffect(() => {
		if (appID === '49c33cf3-6213-4830-97ed-90196608661c') {
			setStyleChat({
				chatLogo: RBLogo,
				brandColor: '#A6823C',
				agentBubbleColor: '#F1E9DA',
				userBubbleColor: '#A6823C',
				chatOpenCloseColor: '#A6823C'
			});
		}
		if (appID && appID !== '49c33cf3-6213-4830-97ed-90196608661c') {
			onGetChatStyle();
		}
		// show hide widget
		// 4c8fd9a9-a466-40de-aff5-92afd48b9f85 Form Assembly wanted chatbot to be expanded by default
		if (appID == '4c8fd9a9-a466-40de-aff5-92afd48b9f85') {
			setIsWidgetExpanded(true);
		}
		if (demoPage === 'true') {
			setChatWidgetVisible(true);
		} else {
			onHideShowWidget();
		}
	}, [appID]);

	const onGetChatStyle = async () => {
		await axios
			.get(`${GATEWAY_PLAYGROUND_API_URL}/marie/style?chat_app_id=${appID}`)
			.then(res => {
				if (res.data.success && !_.isEmpty(res.data.style)) {
					const dataStyle = res.data.style;

					setStyleChat({
						...dataStyle,
						brandColor: dataStyle?.brandColor || dataStyle?.backgroundColor,
						botIconUrl: dataStyle?.botIconUrl,
						agentBubbleColor: dataStyle?.agentBubbleColor || dataStyle?.agentMessageBackgroundColor,
						userBubbleColor: dataStyle?.userBubbleColor || dataStyle?.userMessageBackgroundColor,
						chatLogo: dataStyle?.imgSrc || dataStyle?.chatLogo || null,
						agentTextColor: dataStyle?.agentTextColor || dataStyle?.agentMessageTextColor,
						userTextColor: dataStyle?.userTextColor || dataStyle?.userMessageTextColor,
						chatOpenCloseColor: dataStyle?.chatOpenCloseColor || dataStyle?.brandColor || dataStyle?.backgroundColor
					});
				}
			})
			.catch(e => {
				console.error(e);
				setStyleChat(CHAT_DEFAULT_STATE);
			});
	};

	const onHideShowWidget = async () => {
		await axios.get(`${CHAT_API_URL}/application/${appID}/chat-available`).then(res => {
			if (res.data.success) {
				setChatWidgetVisible(true);
			}
		});
	};

	if (!isChatWidgetVisible || styleChat === null) {
		return null;
	}

	if (playground === 'true' && isChatWidgetVisible && styleChat !== null) {
		return (
			<ChatContainer
				companyConfig={styleChat}
				onExpandChange={setIsWidgetExpanded}
				appID={appID}
				playground={true}
				appPosition={appPosition}
				hideHeader={hideHeader}
				hideFooter={hideFooter}
				extension={extension}
				flowVersionUuid={flowVersionUuid}
				onCustomHandOff={onCustomHandOff}
			/>
		);
	}

	return (
		<div className={classes.chatWidgetContainer}>
			{isWidgetExpanded && (
				<ChatContainer
					companyConfig={styleChat}
					onExpandChange={setIsWidgetExpanded}
					appID={appID}
					isMobile={isMobile}
					appPosition={appPosition}
					hideHeader={hideHeader}
					hideFooter={hideFooter}
					extension={extension}
					flowVersionUuid={flowVersionUuid}
					onCustomHandOff={onCustomHandOff}
					maxHeight={containerHeight}
				/>
			)}
			<WidgetButton
				appPosition={appPosition}
				companyConfig={styleChat}
				isExpanded={isWidgetExpanded}
				onExpandChange={setIsWidgetExpanded}
				isMobile={isMobile}
				containerHeight={containerHeight}
			/>
		</div>
	);
}
