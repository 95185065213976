import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { animated, useSpring, easings } from '@react-spring/web';
import BouncingDotsLoader from './utils/BouncingDotsLoader';
import { ReactComponent as UserMessageTailLogo } from '../assets/user-message-bubble-tail.svg';
import { ReactComponent as AgentMessageTailLogo } from '../assets/agent-message-bubble-tail.svg';
import '../assets/css/typing.css';

const useStyles = makeStyles(_ => ({
	messageContainer: {
		textAlign: 'left',
		display: 'flex',
		flexFlow: 'column wrap'
	},
	agentMessageContainer: {
		alignItems: 'flex-start',
		alignContent: 'flex-start',
		marginBottom: '10px'
	},
	userMessageContainer: {
		alignItems: 'flex-end',
		alignContent: 'flex-end'
	},
	agentMessage: {
		color: '#B59048',
		marginLeft: '7px'
	},
	userMessage: {
		color: '#3F2A1C',
		marginRight: '7px'
	},
	chatText: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '400'
	},
	messageSender: {
		height: '14px',
		fontSize: '10px',
		lineHeight: '130%',
		marginBottom: '5px'
	},
	bubbleContainer: {
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
    
	},
	messageBubble: {
		maxWidth: '30px',
		minWidth: '10px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		padding: '1px 12px',
		borderRadius: '18px',
		order: '0',
		alignSelf: 'stretch',
		flexGrow: '1'
	},
	agentMessageBubble: {
		background: '#F1E9DA'
	},
	userMessageBubble: {
		background: '#A6823C'
	},
	agentMessageTail: {
		padding: '0px',
		position: 'absolute',
		width: '16.42px',
		height: '2px',
		left: '-4.5px',
		bottom: '18px',
		'& path': {
			fill: '#F1E9DA'
		}
	},
	userMessageTail: {
		padding: '0px',
		position: 'absolute',
		width: '16.42px',
		height: '2px',
		right: '-4.5px',
		bottom: '18px',
		'& path': {
			fill: '#A6823C'
		}
	},
	message: {
		fontSize: '14px',
		lineHeight: '18px',
		letterSpacing: '-0.408px',
		fontFeatureSettings: "'case' on",
		color: '#FFFFFF'
	},
	businessMessage: {
		color: '#000000'
	},

	messageTime: {
		height: '16px',
		fontSize: '12px',
		lineHeight: '130%',
		color: '#7A869A',
		marginTop: '4px',
		marginBottom: '4px'
	},
	agentMessageTime: {
		marginLeft: '7px'
	},
	userMessageTime: {
		marginRight: '7px'
	},
	marginBottom: {
		marginBottom: '8px'
	}
}));

export default function ChatMessageTyping({ companyConfig, playground }) {
	const classes = useStyles();
	console.log(companyConfig, 'companyConfig');
	return (
	
			<div className={classes.bubbleContainer}>
				<span style={{ alignSelf: 'flex-start' }}>
					{' '}
					<img
						src={companyConfig.botIconUrl ? companyConfig.botIconUrl : companyConfig.chatLogo}
						alt="logo"
						style={{
							maxWidth: '36px',
							maxHeight: '36px',
							overflow: 'hidden',
							borderRadius: '36px',
							marginRight: '10px',
							alignSelf: 'flex-start'
						}}
					/>
				</span>
				<div
					className={`${classes.messageBubble} ${classes.agentMessageBubble}`}
					style={{
						maxWidth:'62px',
						background: companyConfig.agentBubbleColor
					}}
				>
					<BouncingDotsLoader />
				</div>
			</div>
		
	);
}
