import React from 'react';

const MinimizeBtn = ({ width = '20', height = '16', fill = 'white', ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			stroke={fill}
			strokeWidth="4"
			strokeLinecap="round"
			strokeLinejoin="round"
			className="tabler-icon tabler-icon-minus "
		>
			<path d="M5 12l14 0"></path>
		</svg>
	);
};

export default MinimizeBtn;
