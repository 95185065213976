import { useEffect } from 'react';
import CompressLogo from '../assets/img/comp-logo.svg';
import ExpDefaultLogo from '../assets/img/exp-default-log.svg';
import GorgiasIcon from '../assets/img/gorgias-logo.svg';
import { makeStyles, styled } from '@mui/styles';
import { useSpring, animated } from '@react-spring/web';

const useStyles = makeStyles(_ => ({
	widgetContainer: {
		position: 'relative',
		width: '72px',
		height: '81px',
		alignItems: 'center',
		display: 'flex',
		paddingBottom: '10px'
	},
	widget: {
		position: 'absolute'
	},
	widgetButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: 'none',
		boxShadow: 'none',
		position: 'relative',
		width: 48,
		height: 48,
		background: '#0065FF',
		borderRadius: '8px',
		cursor: 'pointer',
		marginTop: '1rem',
		transition: 'transform 0.2s',
		'&:hover': {
			transform: 'scale(1.1)'
		}
	},
	widgetButtonGorgias: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: 'none',
		boxShadow: 'none',
		position: 'relative',
		width: 60,
		height: 60,
		background: '#0065FF',
		borderRadius: '100px',
		cursor: 'pointer',
		transition: 'transform 0.2s',
		'&:hover': {
			transform: 'scale(1.1)'
		},
		margin: '0.5rem',

	}
}));

export default function WidgetButton({ isExpanded, onExpandChange, companyConfig, appPosition, isMobile, containerHeight }) {
	const classes = useStyles();
	const { transform } = useSpring({
		transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
		config: { tension: 210, friction: 20 }
	});

	useEffect(() => {
		let message = {
			type: 'kodif-widget-message',
			height: isExpanded ? `${containerHeight}px` : '90px',
			width: isExpanded ? `${document.body.scrollWidth + 20}px` : '90px'
		};
		if (JSON.parse(isMobile) && isExpanded) {
			message = {
				type: 'kodif-widget-message',
				height: '100%',
				width: '100%'
			};
		}
		// window.top refers to parent window
		window.top.postMessage(message, '*');
	}, [isExpanded, isMobile]);
	return (
		<div
			id="chat-widget-button"
			className={classes.widgetContainer}
			style={{ marginLeft: appPosition === 'right' && 'auto', justifyContent: isExpanded ? 'right' : 'center' }}
		>
			<animated.div className={classes.widget}>
				<button
					className={companyConfig.launcherButton ? classes.widgetButtonGorgias : classes.widgetButton}
					onClick={() => onExpandChange(true)}
					style={{ background: companyConfig.chatOpenCloseColor, display: isExpanded ? 'none' : 'flex' }}
				>
					<animated.img
						src={companyConfig?.launcherButton ? GorgiasIcon : ExpDefaultLogo}
						alt="compress-logo"
						style={{ transform }}
					/>
				</button>
			</animated.div>
		</div>
	);
}
