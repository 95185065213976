// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Proxima-Nova/Web-Fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Proxima-Nova/Web-Fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Proxima-Nova/Web-Fonts/proximanova_semibold_macroman/proximanova-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Proxima-Nova/Web-Fonts/proximanova_semibold_macroman/proximanova-semibold-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'proximanova-regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proximanova-semibold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
    font-weight: 600;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC;0DACmH;IACnH,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,mCAAmC;IACnC;0DACqH;IACrH,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'proximanova-regular';\n    src: url('../fonts/Proxima-Nova/Web-Fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff2') format('woff2'),\n    url('../fonts/Proxima-Nova/Web-Fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n}\n@font-face {\n    font-family: 'proximanova-semibold';\n    src: url('../fonts/Proxima-Nova/Web-Fonts/proximanova_semibold_macroman/proximanova-semibold-webfont.woff2') format('woff2'),\n    url('../fonts/Proxima-Nova/Web-Fonts/proximanova_semibold_macroman/proximanova-semibold-webfont.woff') format('woff');\n    font-weight: 600;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
