import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((_) => ({
  quickReplyBubble: {
    fontFamily: 'Inter',
  boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 12px',
    gap: '10px',
    width: 'auto',
    maxWidth: '344px',
    minHeight: '30px',
    border: '1.5px solid  transparent',
    borderRadius: '12px',
    flex: 'none',
    order: '0',
    flexGrow: '1',
    color: '#3062D4',
     opacity: "1",
    '&:hover': {
      opacity: "0.8",
      backgroundColor: "rgba(0,0,0,0.05)",

		},
   
    '& p': {
      width: 'fit-content',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '28px',
      flex: 'none',
      order: '0',
      flexGrow: '0',
    },
 
  },
  quickReplyBubbleMini: {
    fontFamily: 'Inter',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    padding: '10px 12px',
    width: 'auto',
    maxHeight: "200px",
    border: '1.5px solid transparent',
    borderRadius: '12px',
    fontSize: '14px',
     color: '#3062D4',
    opacity: "1",
    '&:hover': {
      opacity: "0.6",
		},
  
    '& p': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: 0,
    
    },
  },
}));

export default function ChatQuickReply({ companyConfig, reply, onQuickReplyClick, quickRepliesCount, }) {
  const classes = useStyles(companyConfig);
  let className = classes.quickReplyBubble;

  if (quickRepliesCount > 3) {
    className = classes.quickReplyBubbleMini;
  }

  return (
    <button
      onClick={() => onQuickReplyClick(reply)}
      className={className}
      style={{backgroundColor: companyConfig?.quickReplyBubbleColor,
       color: companyConfig?.quickReplyTextColor,
       border: `border: '1.5px solid  ${companyConfig?.quickReplyBubbleColor}',`
        }}
    >
      {reply.label}
    </button>
  );
}
