import React from 'react';

const RefreshIcon = ({ fill = 'white', stroke = 'none', width = '16', height = '16', onClick }) => (
	<svg width={width} height={height} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
		<path
			d="M14.567 2.35C13.117 0.9 11.127 0 8.91699 0C6.79526 0 4.76043 0.842855 3.26014 2.34315C1.75985 3.84344 0.916992 5.87827 0.916992 8C0.916992 10.1217 1.75985 12.1566 3.26014 13.6569C4.76043 15.1571 6.79526 16 8.91699 16C12.647 16 15.757 13.45 16.647 10H14.567C13.747 12.33 11.527 14 8.91699 14C7.32569 14 5.79957 13.3679 4.67435 12.2426C3.54913 11.1174 2.91699 9.5913 2.91699 8C2.91699 6.4087 3.54913 4.88258 4.67435 3.75736C5.79957 2.63214 7.32569 2 8.91699 2C10.577 2 12.057 2.69 13.137 3.78L9.91699 7H16.917V0L14.567 2.35Z"
			fill={fill}
			stroke={stroke}
		/>
	</svg>
);

export default RefreshIcon;
