import { Provider, ErrorBoundary } from '@rollbar/react';
import ChatWidgetContainer from './components/ChatWidgetContainer';
import { rollbarConfig } from './constants';
import './App.css';
import { useState, useEffect } from 'react';

function App() {
	const [kodifSettingsReady, setKodifSettingsReady] = useState(false);

	useEffect(() => {
		// Listen for messages from the parent window
		const handleMessage = event => {
			if (event.data.source === 'parent' && event.data.type === 'kodifSettingsReady') {
				setKodifSettingsReady(true);
			}
		};

		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	const onCustomHandOff = (messages, userEmail) => {
		if (kodifSettingsReady === true) {
			// Send a message to the parent window to call the function
			window.parent.postMessage(
				{
					type: 'callFunction',
					functionName: 'customHandOff',
					args: {
						user_data: {
							global: {
								email: userEmail
							}
						},
						chatter_transcript: messages
					}
				},
				'*'
			);
		}
	};

	const kodifReadyCallback = () => {
		if (kodifSettingsReady === true) {
			window.parent.postMessage(
				{
					type: 'callFunction',
					functionName: 'kodifReadyCallback'
				},
				'*'
			);
		}
	};

	useEffect(() => {
		kodifReadyCallback();
	}, [kodifSettingsReady]);

	return (
		<Provider config={rollbarConfig}>
			<ErrorBoundary extra={error => console.log(error)}>
				<ChatWidgetContainer onCustomHandOff={onCustomHandOff} />
			</ErrorBoundary>
		</Provider>
	);
}

export default App;
