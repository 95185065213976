import moment from 'moment';

const convertMessage = (message) => {
  const formatDate = moment(message.createdAt).format('hh:mm a');

  const formattedText = message.content.text.replace(/\\n/g, '\n');

  return {
    id: message.id,
    type: message.user.type,
    messageType: message.content.type,
    senderName: message.user.name,
    time: formatDate,
    value: formattedText,
    encodedAttachment: message.content?.encodedAttachment || message.content?.url || '',
    thumbsUpDown: message.content?.thumbsUpDown || null,
    votingEnabled: message.content?.votingEnabled || false,
    delayBeforeShowMs: message.content?.delayBeforeShowMs || 0,

  };
};

const getHoursAndMinutes = (date = new Date()) => {
  return padTo2Digits(date.getHours()) + ':' + padTo2Digits(date.getMinutes());
};

const padTo2Digits = (num) => {
  return String(num).padStart(2, '0');
};

const LOCAL_FILE_TYPES = [
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml',
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];
const LOCAL_FILE_ACCEPT = 'image/*, .xls, .xlsx, .pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

const FILE_TYPES = file => {
  return LOCAL_FILE_TYPES.includes(file);
};

const FILE_SIZES = size => {
  const fileSize = Math.round(size / 1024 / 1024);
  return fileSize < 10;
};

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};


const searchWordInTypes = (value = '', elements = []) => {
    for (let el of elements) {
        if (value.split('base64')[0].search(el) >= 0) {
            return true;
        }
    }
    return false;
};

const searchWordInTypesForAlert = (value, elements) => {
    for (let el of elements) {
        if (value.search(el) >= 0) {
            return true;
        }
    }
    return false;
};

const resultSearchWordInTypes = (value, elements) => {
    for (let el of elements) {
        if (value.split('base64')[0].search(el) >= 0) {
            return el;
        }
    }
    return null;
};

export {
    convertMessage,
    convertBase64,
    FILE_SIZES,
    FILE_TYPES,
    LOCAL_FILE_ACCEPT,
    searchWordInTypes,
    resultSearchWordInTypes,
    searchWordInTypesForAlert
};
