// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, div[id*=kodif-chat-widget-container] {
    height: 100%;
}
body {
    overflow: hidden;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/chat.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,SAAS;AACb","sourcesContent":["html, body, div[id*=kodif-chat-widget-container] {\n    height: 100%;\n}\nbody {\n    overflow: hidden;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
