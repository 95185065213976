import { v4 as uuidv4 } from "uuid";

// ENV Variables
const PROD_API_URL = "chat.kodif.io";
const DEV_API_URL = "chat-staging.kodif.io";
export const CHAT_API_URL = "https://chat.kodif.io/api/v1";
export const GATEWAY_PLAYGROUND_API_URL = 'https://gateway.kodif.io/playground/api/v1';

// export const BASE_URL =
//   process.env.NODE_ENV === "production" ? PROD_API_URL : DEV_API_URL;
export const BASE_URL = PROD_API_URL;

export const WEB_SOCKET_URL = `wss://${BASE_URL}/ws`;
export const HARDCODED_USER = {
  id: uuidv4(),
  name: "You",
  type: "user",
};

export const rollbarConfig = {
  accessToken: 'c703c1d101ee4619b4508ac7c6c31346',
  environment: 'production',
};