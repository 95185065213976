// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bouncing-loader {
    display: flex;
    justify-content: center;
    align-items: center; /* Added to vertically center dots */
    margin: 10px auto 5px;
}

.bouncing-loader > div {
    width: 7px;
    height: 7px;
    margin: 8px 2px; /* Adjusted margin-top to lower the dots */
    border-radius: 50%;
    background-color: #000;
    opacity: 1;
    animation: bouncing-loader 0.5s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-4px);
    }
}

.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/typing.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB,EAAE,oCAAoC;IACzD,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,eAAe,EAAE,0CAA0C;IAC3D,kBAAkB;IAClB,sBAAsB;IACtB,UAAU;IACV,kDAAkD;AACtD;;AAEA;IACI;QACI,YAAY;QACZ,2BAA2B;IAC/B;AACJ;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".bouncing-loader {\n    display: flex;\n    justify-content: center;\n    align-items: center; /* Added to vertically center dots */\n    margin: 10px auto 5px;\n}\n\n.bouncing-loader > div {\n    width: 7px;\n    height: 7px;\n    margin: 8px 2px; /* Adjusted margin-top to lower the dots */\n    border-radius: 50%;\n    background-color: #000;\n    opacity: 1;\n    animation: bouncing-loader 0.5s infinite alternate;\n}\n\n@keyframes bouncing-loader {\n    to {\n        opacity: 0.1;\n        transform: translateY(-4px);\n    }\n}\n\n.bouncing-loader > div:nth-child(2) {\n    animation-delay: 0.2s;\n}\n\n.bouncing-loader > div:nth-child(3) {\n    animation-delay: 0.4s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
