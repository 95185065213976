import React, { useState } from 'react';
import { Snackbar, Slide, Button, Box, Typography, Rating, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
/* import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Divider from '@mui/material/Divider'; */
import { ReactComponent as BadIcon } from "../assets/img/emoji/not-happy.svg";
import { ReactComponent as BadIconHover } from "../assets/img/emoji/not-happy-h.svg";
import { ReactComponent as OkIcon } from "../assets/img/emoji/ok.svg";
import { ReactComponent as OkIconHover } from "../assets/img/emoji/ok-hover.svg";
import { ReactComponent as ExIcon } from "../assets/img/emoji/ex.svg";
import { ReactComponent as ExIconHover } from "../assets/img/emoji/excellent-hover.svg";
const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));




function TransitionUp(props) {
    return <Slide {...props} direction="up"/>;
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} variant="filled" {...props} />;
});

const SESSION_EXPIRED = 'Session ended';
const SESSION_END = 'Chat has ended';
const MODAL_VARIANT = false;

export default function ChatCSAT({ companyConfig, message, onEndChat, sessionText, onNewChat }) {
    const [valueRating, setValueRating] = useState(2);
    const [valueText, setValueText] = useState('');

    const checkValidText = valueText.length > 3;

    const customIcons = {
        1: {
            icon: valueRating === 1 ? <BadIconHover /> : <BadIcon />,
            label: 'Bad',
        },
        2: {
            icon: valueRating === 2 ? <OkIconHover /> : <OkIcon />,
            label: 'Ok',
        },
        3: {
            icon: valueRating === 3 ? <ExIconHover /> : <ExIcon />,
            label: 'Excellent',
        },
        // 4: {
        //     icon: <SentimentSatisfiedAltIcon color="success" fontSize="large" />,
        //     label: 'Satisfied',
        // },
        // 5: {
        //     icon: <SentimentVerySatisfiedIcon color="success" fontSize="large" />,
        //     label: 'Very Satisfied',
        // },
    };

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value].icon}</span>;
    }

    // if (MODAL_VARIANT) {
    //     return (
    //         <Snackbar
    //             TransitionComponent={TransitionUp}
    //             // autoHideDuration={10000}
    //             anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    //             open={openDialog}
    //             onClose={handleCloseDialog}
    //             sx={{
    //                 '&': {
    //                     position: 'absolute',
    //                     width: '100%',
    //                     marginBottom: '10px',
    //                     height: 'calc(100% - 105px)',
    //                     maxWidth: '362px'
    //                 }
    //             }}
    //         >
    //             <Alert
    //                 action={<></>}
    //                 icon={false}
    //                 onClose={handleCloseDialog}
    //                 sx={{
    //                     width: '100%',
    //                     height: '100%',
    //                     color: '#172B4D',
    //                     borderRadius: '8px',
    //                     boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.15)',
    //                     background: 'white',
    //                     fontFamily: 'Inter',
    //                     fontSize: '12px',
    //                     padding: 0,
    //                     '& .MuiAlert-action': {
    //                         padding: '0',
    //                         display: 'none'
    //                     },
    //                     '& .MuiAlert-message': {
    //                         width: '100%',
    //                         padding: '0'
    //                     }
    //                 }}
    //             >
    //                 <Box position="relative" fontFamily="Inter" margin="0 -10px">
    //                     <Box
    //                         width="100%"
    //                         height="34px"
    //                         display="flex"
    //                         justifyContent="center"
    //                         alignItems="center"
    //                         textTransform="uppercase"
    //                         fontSize="10px"
    //                         color="#7A869A"
    //                         sx={{
    //                             background: '#DFE1E6',
    //                         }}
    //                     >
    //                         {sessionText}
    //                     </Box>
    //                     <Typography
    //                         textAlign="center"
    //                         fontFamily="Inter"
    //                         fontSize="16px"
    //                         fontWeight={600}
    //                         color="#172B4D"
    //                         margin="25px 0 22px"
    //                     >
    //                         {message}
    //                     </Typography>
    //                     <Box textAlign="center">
    //                         <StyledRating
    //                             max={3}
    //                             value={valueRating}
    //                             onChange={(event, newValue) => {
    //                                 setValueRating(newValue);
    //                             }}
    //                             name="highlight-selected-only"
    //                             IconContainerComponent={IconContainer}
    //                             // getLabelText={(value) => customIcons[value].label}
    //                             highlightSelectedOnly
    //                         />
    //                     </Box>
    //                     <Typography
    //                         textAlign="center"
    //                         fontFamily="Inter"
    //                         fontSize="14px"
    //                         color="#5E6C84"
    //                         margin="33px 0 15px"
    //                     >
    //                         Do you have anything to add?
    //                     </Typography>
    //                     <Box maxWidth={310} justifyContent="center" display="flex" margin="0 auto">
    //                         <TextField
    //                             value={valueText}
    //                             onChange={e => setValueText(e.target.value)}
    //                             fullWidth
    //                             id="outlined-multiline-static"
    //                             // label="Multiline"
    //                             multiline
    //                             rows={6}
    //                             placeholder="Your comment (optional)"
    //                             sx={{
    //                                 "& textarea::placeholder": {
    //                                     fontSize: "12px",
    //                                     fontWeight: 300
    //                                 }
    //                             }}
    //                         />
    //                     </Box>
    //                     <Box maxWidth={310} justifyContent="center" display="flex" margin="41px auto 5px">
    //                         <Button
    //                             disabled={!checkValidText}
    //                             variant="contained"
    //                             fullWidth
    //                             sx={{
    //                                 background: '#0052CC',
    //                                 textTransform: 'capitalize',
    //                                 fontWeight: 500,
    //                                 fontFamily: 'Inter'
    //                             }}
    //                             onClick={onEndChat}
    //                         >
    //                             Send
    //                         </Button>
    //                     </Box>
    //                 </Box>
    //             </Alert>
    //         </Snackbar>
    //     );
    // }

    // if (sessionText === SESSION_END) {
    //     return (
    //         <Box position="relative" fontFamily="Inter" margin="0 -10px">
    //             <Box
    //                 width="100%"
    //                 height="34px"
    //                 display="flex"
    //                 justifyContent="center"
    //                 alignItems="center"
    //                 textTransform="uppercase"
    //                 fontSize="10px"
    //                 color="#7A869A"
    //                 sx={{
    //                     background: '#DFE1E6',
    //                 }}
    //             >
    //                 {sessionText}
    //             </Box>
    //             {/*<Typography*/}
    //             {/*    textAlign="center"*/}
    //             {/*    fontFamily="Inter"*/}
    //             {/*    fontSize="16px"*/}
    //             {/*    fontWeight={600}*/}
    //             {/*    color="#172B4D"*/}
    //             {/*    margin="25px 0 22px"*/}
    //             {/*>*/}
    //             {/*    {message}*/}
    //             {/*</Typography>*/}
    //             {/*<Box textAlign="center">*/}
    //             {/*    <StyledRating*/}
    //             {/*        max={3}*/}
    //             {/*        value={valueRating}*/}
    //             {/*        onChange={(event, newValue) => {*/}
    //             {/*            setValueRating(newValue);*/}
    //             {/*        }}*/}
    //             {/*        name="highlight-selected-only"*/}
    //             {/*        IconContainerComponent={IconContainer}*/}
    //             {/*        // getLabelText={(value) => customIcons[value].label}*/}
    //             {/*        highlightSelectedOnly*/}
    //             {/*    />*/}
    //             {/*</Box>*/}
    //             {/*<Typography*/}
    //             {/*    textAlign="center"*/}
    //             {/*    fontFamily="Inter"*/}
    //             {/*    fontSize="14px"*/}
    //             {/*    color="#5E6C84"*/}
    //             {/*    margin="33px 0 15px"*/}
    //             {/*>*/}
    //             {/*    Do you have anything to add?*/}
    //             {/*</Typography>*/}
    //             {/*<Box maxWidth={310} justifyContent="center" display="flex" margin="0 auto">*/}
    //             {/*    <TextField*/}
    //             {/*        value={valueText}*/}
    //             {/*        onChange={e => setValueText(e.target.value)}*/}
    //             {/*        fullWidth*/}
    //             {/*        id="outlined-multiline-static"*/}
    //             {/*        // label="Multiline"*/}
    //             {/*        multiline*/}
    //             {/*        rows={6}*/}
    //             {/*        placeholder="Your comment (optional)"*/}
    //             {/*        sx={{*/}
    //             {/*            "& textarea::placeholder": {*/}
    //             {/*                fontSize: "12px",*/}
    //             {/*                fontWeight: 300*/}
    //             {/*            }*/}
    //             {/*        }}*/}
    //             {/*    />*/}
    //             {/*</Box>*/}
    //             <Box
    //                 maxWidth={310}
    //                 justifyContent="center"
    //                 display="flex"
    //                 flexDirection="column"
    //                 margin="21px auto 5px"
    //             >
    //             {/*    <Button*/}
    //             {/*        disabled={!checkValidText}*/}
    //             {/*        variant="contained"*/}
    //             {/*        fullWidth*/}
    //             {/*        sx={{*/}
    //             {/*            background: companyConfig.brandColor,*/}
    //             {/*            textTransform: 'unset',*/}
    //             {/*            fontWeight: 500,*/}
    //             {/*            fontFamily: 'Inter',*/}
    //             {/*            '&:hover': {*/}
    //             {/*                background: companyConfig.brandColor,*/}
    //             {/*            }*/}
    //             {/*        }}*/}
    //             {/*        onClick={onEndChat}*/}
    //             {/*    >*/}
    //             {/*        Send*/}
    //             {/*    </Button>*/}
    //             {/*    <Divider color="gray" sx={{ fontSize: 10, margin: '20px 0', color: 'gray' }}>OR</Divider>*/}
    //                 <Button
    //                     variant="text"
    //                     sx={{
    //                         // background: companyConfig.brandColor,
    //                         fontWeight: 500,
    //                         textTransform: 'unset',
    //                         fontFamily: 'Inter',
    //                         color: companyConfig.brandColor,
    //                         '&:hover': {
    //                             color: companyConfig.brandColor,
    //                         }
    //                     }}
    //                     onClick={onNewChat}
    //                 >
    //                     Start a New Conversation
    //                 </Button>
    //             </Box>
    //         </Box>
    //     )
    // }

    return (
        <Box position="relative"  fontFamily="Inter" margin="0 0" bottom ="18px" left="0" width="100%" backgroundColor="#FFFFFF" boxShadow= "inset 0px 0px 15px rgba(0, 0, 0, 0)" >
            <Box
                width="100%"
                height="48px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                textTransform="uppercase"
                fontSize="10px"
                color="#7A869A"
            >
                {sessionText}
            </Box>
            {/*<Typography*/}
            {/*    textAlign="center"*/}
            {/*    fontFamily="Inter"*/}
            {/*    fontSize="16px"*/}
            {/*    fontWeight={500}*/}
            {/*    color="#172B4D"*/}
            {/*    margin="25px 0 22px"*/}
            {/*>*/}
            {/*    {message}*/}
            {/*</Typography>*/}
            <Box
                maxWidth={364}
                // justifyContent="center"
                // display="flex"
                // flexDirection="column"
                margin="1px auto 16px"
                textAlign="center"
            >
                <Button
                    variant="text"
                    sx={{
                        backgroundColor: companyConfig.brandColor,
                        fontWeight: 500,
                        textTransform: 'unset',
                        borderRadius: "12px",
                        width: "364px",
                        height: "48px",
                        padding: "10px 16px",
                        fontFamily: 'Inter',
                        color: companyConfig?.headerIconColor || "white",
                        alignItems: "center", 
                        '&:hover': {
                            backgroundColor: companyConfig.brandColor,
                        },
                        '@media (max-width: 600px)': {
                            width: '320px',
                        },
                    }}
                    onClick={onNewChat}
                >
                    Start new conversation
                </Button>
            </Box>
        </Box>
    );
}