import { Alert } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import GridOnIcon from "@mui/icons-material/GridOn";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React from "react";
import {resultSearchWordInTypes} from "../../utils";

import DocIcon from '../../assets/img/icons/docs.png';
import SheetIcon from '../../assets/img/icons/sheets.png';
import PDFIcon from '../../assets/img/icons/pdf.png';

const FileComponent = ({ encodedAttachment, elements = [] }) => {
    switch (resultSearchWordInTypes(encodedAttachment, elements)) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return (
                <Alert icon={<img src={DocIcon} alt="" width={20} height={20} />} severity="info">
                    Document Docs
                </Alert>
            )
        case 'application/msword':
            return (
                <Alert icon={<img src={DocIcon} alt="" width={20} height={20} />} severity="info">
                    Document Docs
                </Alert>
            )
        case 'docx':
            return (
                <Alert icon={<img src={DocIcon} alt="" width={20} height={20} />} severity="info">
                    Document Docs
                </Alert>
            )
        case 'doc':
            return (
                <Alert icon={<img src={DocIcon} alt="" width={20} height={20} />} severity="info">
                    Document Docs
                </Alert>
            )
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return (
                <Alert icon={<img src={SheetIcon} alt="" width={20} height={20} />} severity="success">
                    Document Sheets
                </Alert>
            )
        case 'application/vnd.ms-excel':
            return (
                <Alert icon={<img src={SheetIcon} alt="" width={20} height={20} />} severity="success">
                    Document Sheets
                </Alert>
            )
        case 'xlsx':
            return (
                <Alert icon={<img src={SheetIcon} alt="" width={20} height={20} />} severity="success">
                    Document Sheets
                </Alert>
            )
        case 'xls':
            return (
                <Alert icon={<img src={SheetIcon} alt="" width={20} height={20} />} severity="success">
                    Document Sheets
                </Alert>
            )
        case 'pdf':
            return (
                <Alert icon={<img src={PDFIcon} alt="" width={20} height={20} />} severity="error">
                    Document PDF
                </Alert>
            )
        case 'application/pdf':
            return (
                <Alert icon={<img src={PDFIcon} alt="" width={20} height={20} />} severity="error">
                    Document PDF
                </Alert>
            )
        default:
            return <></>;
    }
}

export default FileComponent;