import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stomp } from '@stomp/stompjs';
import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';
import { ReactComponent as PoweredByKodifLogo } from '../assets/powered-by-kodif.svg';
import { BASE_URL, HARDCODED_USER, WEB_SOCKET_URL } from '../constants';
import { convertMessage } from '../utils';
import ChatCSAT from './ChatCSAT';
import ChatCloseConfirmation from './ChatCloseConfirmation';
import ChatInputBox from './ChatInputBox';
import ChatMessage from './ChatMessage';
import ChatMessageTyping from './ChatMessageTyping';
import ChatQuickReply from './ChatQuickReply';

import '../assets/css/fonts.css';
import AlertFile from './utils/AlertFile';
import RefreshIcon from '../assets/RefreshIcon';
import CloseButton from '../assets/CloseBtn';
import MinimizeBtn from '../assets/MinimizeBtn';

const useStyles = makeStyles(_ => {
	return {
		chatContainer: {
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			width: '400px',
			height: '100%',
			boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(27, 36, 44, 0.1)',
			borderRadius: '16px',
			background: '#FFF',
			justifyContent: 'flex-end'
		},
		chatContainerMobile: {
			display: 'flex',
			flexDirection: 'column',
			position: 'fixed',
			top: '0',
			left: '0',
			width: '100vw',
			height: '100vh',
			boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(27, 36, 44, 0.1)',
			borderRadius: '1px',
			zIndex: '11',
			background: '#FFF'
		},
		chatContainerPlayground: {
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			width: '100%',
			height: '100%',
			borderRadius: '0',
			background: '#A6823C'
		},
		chatHeader: {
			height: '58px',
			padding: '9px 12px',
			flex: '0 0 0',
			display: 'flex',
			justifyContent: 'space-between'
		},
		chartHeaderLogoContainer: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			cursor: 'pointer',
			minHeight: '45px',
			overflow: 'hidden'
		},
		chartHeaderRefreshIcon: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			cursor: 'pointer',
			margin: '0 0 0 auto',
			padding: 0,
			background: 'transparent',
			border: 0,
			minHeight: '35px',
			minWidth: '35px',
			maxHeight: '35px',
			overflow: 'hidden',
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.125)',
				borderRadius: '50%'
			}
		},
		chartHeaderMinimize: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			cursor: 'pointer',
			minHeight: '35px',
			minWidth: '35px',
			maxHeight: '35px',
			overflow: 'hidden',
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.125)',
				borderRadius: '50%'
			}
		},
		chatLoading: {
			width: '50px',
			margin: 'auto',
			padding: '1px',
			aspectRatio: '1',
			display: 'flex',
			alignItems: 'center',
			maxHeight: '48px',
			justifyContent: 'center',
			color: '#FFFFFF',
			borderRadius: '50%',
			background: '#25B09B',
			'--_m': 'conic-gradient(#0000 10%,#000), linear-gradient(#000 0 0) content-box',
			WebkitMask: 'var(--_m)',
			mask: 'var(--_m)',
			WebkitMaskComposite: 'source-out',
			maskComposite: 'subtract',
			animation: '$l3 1s infinite linear'
		},
		'@keyframes l3': {
			to: { transform: 'rotate(1turn)' }
		},
		chatContentContainer: {
			width: '100%',
			height: 'calc(100%)',
			boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
			background: '#FFFFFF',
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'flex-end'
		},
		chatContentContainerPlayground: {
			width: '100%',
			height: 'calc(100% - 92px)',
			background: '#FFFFFF',
			borderRadius: '0px 0px 0 0',
			boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'flex-end'
		},
		chatContent: {
			width: '100%',
			height: '100%',
			overflowY: 'auto',
			padding: '10px',
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'flex-end'
		},
		chatContentPlayground: {
			width: '100%',
			height: '100%',
			overflowY: 'auto',
			padding: '10px',
			display: 'flex',
			flexDirection: 'column',
			boxSizing: 'border-box',
			position: 'relative'
		},
		quickRepliesBoxContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '25px 10px 5px',
			gap: '5px',
			position: 'relative',
			bottom: '1%',
			width: '100%',
			background: '#FFFFFF',
			flexWrap: 'wrap',
			marginTop: 'auto',
			boxSizing: 'border-box'
		},
		quickRepliesBoxContainerMini: {
			display: 'inline-flex',
			alignItems: 'center',
			padding: '10px',
			gap: '4px',
			position: 'relative',
			width: '95%',
			background: '#FFFFFF',
			bottom: '1%',
			flexWrap: 'wrap',
			justifyContent: 'center',
			boxSizing: 'border-box',
			marginTop: 'auto'
		},
		chatBoxFooter: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0px',
			gap: '18px',
			position: 'relative',
			width: '100%',
			height: '30px'
			// background: '#E7E6E4',
			// boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.1)',
			// borderRadius: '0px 0px 8px 8px',
		},
		chatBoxFooterPlayground: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0px',
			gap: '18px',
			position: 'absolute',
			width: '100%',
			height: '30px',
			background: '#E7E6E4',
			bottom: 0,
			boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.1)',
			borderRadius: '0px 0px 0px 0px'
		},
		chatBoxFooterText: {
			width: '56px',
			height: '13px',
			fontFamily: '\'Inter\'',
			fontStyle: 'normal',
			fontWeight: '400',
			fontSize: '10px',
			lineHeight: '130%',
			display: 'flex',
			alignItems: 'center',
			color: 'rgba(0, 0, 0, 0.33)'
		},
		fileMessageInfo: {
			justifyContent: 'center',
			margin: '10px 0',
			'& div': {
				padding: 0,
				fontSize: '12px'
			}
		},
		errorMessage: {
			boxSizing: 'border-box',
			border: '1 px solid #D8000C',
			padding: '10px',
			color: '#D8000C',
			backgroundColor: '#FFBABA',
			position: 'absolute',
			height: '60px',
			width: '100%',
			bottom: '30px',
			left: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 10,
			fontFamily: 'Inter',
			fontSize: '14px'
		}
	};
});

const DEFAULT_USER_MESSAGE_STATE = {
	type: 'user',
	senderName: 'You',
	time: null,
	value: null
};

const DEFAULT_USER_MESSAGE_FILE_STATE = {
	messageType: 'attachment',
	type: 'user',
	senderName: 'You',
	time: null,
	value: null,
	encodedAttachment: null
};

const stompClient = Stomp.client(WEB_SOCKET_URL);
stompClient.reconnect_delay = 0;
// const isActiveChat = stompClient.active;
const SESSION_EXPIRED_TEXT = 'Session ended';
const SESSION_END_TEXT = 'Chat has ended';

/**
 * Adjusts the chat widget container height based on the visibility of the header, footer, or both.
 */
const adjustChatContainerHeight = (isPlayground, hideHeader, hideFooter) => {
	if (isPlayground) {
		// if on playground
		if (hideHeader && hideFooter) {
			// if both header and footer are hidden
			return '100%';
		} else if (hideHeader) {
			// if only header is hidden
			return 'calc(100% - 30px)';
		} else if (hideFooter) {
			// if only footer is hidden
			return 'calc(100% - 62px)';
		}
		return 'calc(100% - 92px)';
	} else {
		// if not on playground
		return hideHeader ? '100%' : 'calc(100% - 62px)';
	}
};

export default function ChatContainer({
	onExpandChange,
	appID,
	playground,
	companyConfig,
	appPosition,
	hideFooter,
	hideHeader,
	extension,
	flowVersionUuid,
	isMobile,
	onCustomHandOff,
	maxHeight,
}) {
	const containerHeight = adjustChatContainerHeight(playground, hideHeader, hideFooter);
	const textInputRef = useRef(null);
	const fileUploadRef = useRef(null);
	const chatWindowRef = useRef(null);
	const quickRepliesContainerRef = useRef(null);

	const [conversationId, setConversationId] = useState(null);
	const [chatMessages, setChatMessages] = useState([]);
	const [userMessage, setUserMessage] = useState('');
	const [userUpdFile, setUserUpdFile] = useState('');
	const [submitType, setSubmitType] = useState('text');
	const [showUploadButton, setShowUploadButton] = useState(false);
	const [showPreviewImg, setShowPreviewImg] = useState(false);
	const [userFileMessage, setUserFileMessage] = useState('');
	const [isCloseMenuActive, setIsCloseMenuActive] = useState(false);
	const [isActiveChat, setIsActiveChat] = useState(stompClient.active || false);
	const [isNewChat, setIsNewChat] = useState(false);
	const [disabledInputBox, setDisabledInputBox] = useState(false);
	const [isActiveTextInput, setIsActiveTextInput] = useState(null);
	const [quickReplies, setQuickReplies] = useState([]);
	const [chatOffsetHeight, setChatOffsetHeight] = useState(69);
	const [isTyping, setIsTyping] = useState(false);
	const [isChatBotMessage, setIsChatBotMessage] = useState(true);
	const [openSnack, setOpenSnack] = useState(false);
	const [openDialog, setOpenDialog] = useState(false); // TODO: remove this line
	const [sessionText, setSessionText] = useState(SESSION_EXPIRED_TEXT);
	const [isHandingOff, setIsHandingOff] = useState(false);
	const [userEmail, setUserEmail] = useState('user@kodif.ai');
	const isQuickReplyActive = quickReplies.length > 0;
	const classes = useStyles(chatOffsetHeight);
	let timer;
	let timerCount = 1;

	useEffect(() => {
		if (playground) {
			if (quickRepliesContainerRef.current !== null) {
				setChatOffsetHeight(quickRepliesContainerRef.current.clientHeight + 25);
			} else if (!isQuickReplyActive) {
				setChatOffsetHeight(69 + 25);
			}
		} else {
			if (quickRepliesContainerRef.current !== null) {
				setChatOffsetHeight(quickRepliesContainerRef.current.clientHeight);
			} else if (!isQuickReplyActive) {
				setChatOffsetHeight(69);
			}
		}
	}, [isQuickReplyActive, quickRepliesContainerRef]);

	const handleCloseDialog = (e, reason) => {
		if (_.isUndefined(reason)) {
			setOpenDialog(false);
		}
	};

	const scrollBottom = (el = document.body) => {
		return (el.scrollTop = el.scrollHeight - el.clientHeight);
	};

	// convert messages from current session to handoff to Gorgeos
	const convertMessageHistory = () => {
		return chatMessages
			.map(({ senderName, value }) => {
				const sender = senderName === 'Chatbot' ? 'BOT' : 'USER';
				return `${sender}: ${value} \n`;
			})
			.join('');
	};

	useEffect(() => {
		if (isHandingOff === true) {
			onCustomHandOff(convertMessageHistory(), userEmail);
			onExpandChange(false);
			setIsHandingOff(false);
		}
	}, [isHandingOff]);

	const connectToChat = async () => {
		try {
			setIsTyping(true);  // On widget open show typing animation instead of an empty screen
			setIsActiveChat(false);
			await stompClient.connect(
				{ user: HARDCODED_USER },
				async () => {
					if (conversationId) {
						timer = setInterval(async () => {
							if (stompClient.connected && conversationId && timerCount > 0) {
								timerCount++;
								await stompClient.send('/app/' + appID + '/ping', {}, conversationId);
							} else {
								setIsTyping(false);
								setOpenSnack(true);
								setDisabledInputBox(true);
								setQuickReplies([]);
								Cookies.remove('kodifConversationId' + appID);
								timerCount = 0;
								clearInterval(timer);
								await stompClient.deactivate();
							}
						}, 10000);
					}

					await stompClient.subscribe('/conv/' + conversationId + '/pong', function (data) {
						if (!_.isEmpty(data.body)) {
							let eventBody;
							try {
								eventBody = JSON.parse(data.body);
							} catch (e) {
								eventBody = data.body;
							}

							if (eventBody === 'SESSION_EXPIRED' || data.body === 'SESSION_END') {
								setIsTyping(false);
								setOpenSnack(true);
								setDisabledInputBox(true);
								clearInterval(timer);
								Cookies.remove('kodifConversationId' + appID);
								// stompClient.disconnect();
								timerCount = 0;
								stompClient.reconnect_delay = 0;
								stompClient.deactivate();
								scrollBottom(chatWindowRef.current);
							}
							if (eventBody === 'SESSION_RESTART') {
								timerCount = 1;
							}
							if (eventBody === 'SESSION_EXPIRED') {
								setSessionText('Session ended');
								timerCount = 0;
							}
							if (eventBody === 'SESSION_END') {
								setSessionText('Chat has ended');
								timerCount = 0;
							}
							if (eventBody === 'DISABLE_TEXT_FIELD') {
								setIsActiveTextInput(false);
								setIsTyping(false);
							}
							if (eventBody === 'ENABLE_TEXT_FIELD') {
								setIsActiveTextInput(true);
							}
							if (typeof eventBody === 'object' && eventBody.type === 'GORGIAS_HANDOFF') {
								setUserEmail(eventBody.email);
								setIsHandingOff(true);
							}
						}
					});

					let messageQueue = [];
					let messageProcessingInProgress = false; // sort of semaphore...

					function processMessageQueue() {
						if (messageProcessingInProgress || messageQueue.length === 0) {
							return;
						}

						// Block the processing of next messages until the current one is done
						messageProcessingInProgress = true;

						// Poll the next message from the queue
						const message = messageQueue.shift();

						// Enable the typing animation
						setIsTyping(true);

						// Delay before processing the message
						setTimeout(() => {
							// Process the message
							if (message.conversation?.respondent === 'CHATBOT') {
								setIsChatBotMessage(true);
							} else {
								setIsChatBotMessage(false);
							}

							setDisabledInputBox(false);

							setShowUploadButton(message.content?.showUploadButton);
							if (message.content?.showTextField === false) {
								setIsActiveTextInput(message.content?.showTextField);
							} else {
								setIsActiveTextInput(true);
							}

							switch (message.content.type) {
								case 'quickReply':
									// eslint-disable-next-line no-case-declarations
									const newArrMsg = message.content.items.map(obj => {
										obj.labelLength = obj.label.length;
										return obj;
									});
									// eslint-disable-next-line no-case-declarations
									const sortReplies = _.orderBy(newArrMsg, ['labelLength'], ['asc']);
									setQuickReplies(sortReplies);
									break;
								default:
									// eslint-disable-next-line no-case-declarations
									const convertedMessage = convertMessage(message);
									setChatMessages(currentChatMessages => [...currentChatMessages, convertedMessage]);
									break;
							}

							// Disable the typing animation after processing the message
							setIsTyping(false);

							// Unblock the queue and continue
							messageProcessingInProgress = false;
							processMessageQueue();
						}, message.content?.delayBeforeShowMs || 0);
					}

					await stompClient.subscribe(
						'/conv/' + conversationId + '/messages',
						function (data) {
							const message = JSON.parse(data.body); // normalize the websocket message
							messageQueue.push(message); // enqueue the message
							processMessageQueue();
						},
						{
							flowVersion: flowVersionUuid
						}
					);
				},
				errorCallback => {
					console.warn('[errorCallback]', errorCallback);
				}
			);
			await stompClient.activate();
			setIsActiveChat(true);
		} catch (e) {
			console.warn(e);
		}
	};

	const handleCloseSnack = (e, reason) => {
		if (_.isUndefined(reason)) {
			setOpenSnack(false);
		}
	};

	const startConversationData = async () => {
		try {
			onExpandChange(true);
			const savedConversationId = Cookies.get('kodifConversationId' + appID) ?? '';
			let urlConv = `https://${BASE_URL}/api/v1/${appID}/conversation`;
			if (playground && !extension) {
				urlConv = `https://${BASE_URL}/api/v1/${appID}/conversation`;
			}

			await axios
				.get(urlConv)
				.then(({ data }) => {
					if (data.success && data.payload !== null) {
						setConversationId(data.payload.id);

						if (!_.isEmpty(data.payload.messages)) {
							const sortMessages = data.payload.messages
								.filter(message => message.content.type !== 'quickReply')
								.sort((d1, d2) => new Date(d1.createdAt).getTime() - new Date(d2.createdAt).getTime());
							const prevMessages = sortMessages.map(message => convertMessage(message));
							setChatMessages(prevMessages);

							setIsTyping(false); // To cancel the initial typing animation when the conversation already exists

							const lastMessage = _.last(data.payload.messages);
							if (lastMessage.content.type === 'quickReply' && lastMessage.user.type === 'business') {
								const newArrMsg = lastMessage.content.items.map(obj => {
									obj.labelLength = obj.label.length;
									return obj;
								});
								const sortReplies = _.orderBy(newArrMsg, ['labelLength'], ['asc']);
								setQuickReplies(sortReplies);
							}

							setShowUploadButton(lastMessage.content?.showUploadButton);
						}
					} else {
						throw new Error('couldnt fetch the conversation');
					}
				})
				.catch(e => {
					if (e.response?.data?.errorMessage === 'SESSION_RESTART') {
						axios.get(`https://${BASE_URL}/api/v1/${appID}/conversation`).then(({ data }) => {
							if (data.success && data.payload !== null) {
								setConversationId(data.payload.id);

								if (!_.isEmpty(data.payload.messages)) {
									const sortMessages = data.payload.messages
										.filter(message => message.content.type !== 'quickReply')
										.sort((d1, d2) => new Date(d1.createdAt).getTime() - new Date(d2.createdAt).getTime());
									const prevMessages = sortMessages.map(message => convertMessage(message));
									setChatMessages(prevMessages);

									const lastMessage = _.last(data.payload.messages);
									if (lastMessage.content.type === 'quickReply' && lastMessage.user.type === 'business') {
										const newArrMsg = lastMessage.content.items.map(obj => {
											obj.labelLength = obj.label.length;
											return obj;
										});
										const sortReplies = _.orderBy(newArrMsg, ['labelLength'], ['asc']);
										setQuickReplies(sortReplies);
									}

									setShowUploadButton(lastMessage.content?.showUploadButton);
								}
							}
						});
					}
				})
				.finally(() => {
					setIsNewChat(false);
					setIsActiveChat(true);
					setDisabledInputBox(false);
				});
			timerCount = 1;
		} catch (e) {
			console.warn(e);
		}
	};

	// fetching a conversation id along with the corresponding message history
	useEffect(() => {
		startConversationData();
	}, []);

	// updating a new conversation id in local storage
	useEffect(() => {
		if (conversationId !== null) {
			const cookieId = Cookies.get('kodifConversationId' + appID);
			connectToChat();
			if (conversationId !== cookieId) {
				Cookies.set('kodifConversationId' + appID, conversationId, { expires: 1 });
			}
		}
		return () => {
			stompClient.disconnect();
			clearInterval(timer);
		};
	}, [conversationId]);

	const onUserMessageChange = e => {
		setUserMessage(e.target.value);
	};

	const debouncedOnUserMessageChange = useCallback(_.debounce(onUserMessageChange, 50), []);

	useEffect(() => {
		return () => {
			stompClient.disconnect();
			clearInterval(timer);
			debouncedOnUserMessageChange.cancel();
		};
	}, []);

	useEffect(() => {
		if (chatWindowRef?.current) {
			chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
		}
	}, [chatMessages, isQuickReplyActive, chatOffsetHeight, isTyping]);

	useEffect(() => {
		if (!_.isEmpty(fileUploadRef?.current?.value)) {
			setShowPreviewImg(true);
			setTimeout(() => scrollBottom(chatWindowRef.current), 300);
		} else {
			setShowPreviewImg(false);
			setUserUpdFile('');
			setSubmitType('text');
		}
	}, [fileUploadRef?.current?.value]);

	useEffect(() => {
		if (stompClient.connected) {
			setDisabledInputBox(false);
		}
	}, [stompClient.connected]);

	const onUserMessageSubmit = (newMessage = userMessage) => {
		try {
			if (newMessage.trim().length > 0 && stompClient.connected) {
				if (isQuickReplyActive) {
					setQuickReplies([]);
				}
				if (isChatBotMessage) {
					setIsTyping(true);
					setDisabledInputBox(true);
				}
				const newUserMessage = { ...DEFAULT_USER_MESSAGE_STATE };
				newUserMessage.value = newMessage;
				newUserMessage.time = moment().format('hh:mm a');
				setChatMessages(currentChatMessages => [...currentChatMessages, newUserMessage]);
				const data = JSON.stringify({
					conversation: { id: conversationId },
					user: HARDCODED_USER,
					content: {
						type: 'text',
						text: newMessage
					}
				});

				stompClient.send(`/app/${appID}/message`, {}, data);
				// setChatBotReplyTimeout({ active: true, maxTimeout: 30 });
				if (newMessage === userMessage) {
					setUserMessage('');
					if (textInputRef.current) {
						textInputRef.current.value = '';
					}
				}
			}
		} catch (e) {
			console.error('[error]', e);
		}
	};

	const onUserFileSubmit = (newFile = userUpdFile, newText = userMessage) => {
		try {
			if (newFile.length > 0 && stompClient.connected) {
				// setUserFileMessage('Loading');
				if (isQuickReplyActive) {
					setQuickReplies([]);
				}
				if (isChatBotMessage) {
					setIsTyping(true);
					setDisabledInputBox(true);
				}
				const newUserFile = { ...DEFAULT_USER_MESSAGE_FILE_STATE };
				newUserFile.encodedAttachment = newFile;
				newUserFile.value = newText;
				newUserFile.time = moment().format('hh:mm a');
				setChatMessages(currentChatMessages => [...currentChatMessages, newUserFile]);
				const data = JSON.stringify({
					conversation: { id: conversationId },
					user: HARDCODED_USER,
					content: {
						type: 'attachment',
						text: newText,
						encodedAttachment: newFile
					}
				});
				stompClient.send(`/app/${appID}/message`, {}, data);
				// setChatBotReplyTimeout({ active: true, maxTimeout: 30 });
				setUserMessage('');
				setUserUpdFile('');
				setSubmitType('text');
				if (textInputRef.current) {
					textInputRef.current.value = '';
				}
				fileUploadRef.current.value = '';
			}
		} catch (e) {
			console.error('[error]', e);
		}
	};

	const onQuickReplyClick = ({ value }) => {
		if (isActiveTextInput === null) {
			setDisabledInputBox(true);
		} else {
			setDisabledInputBox(false);
		}
		onUserMessageSubmit(value);
		setQuickReplies([]);
		setOpenSnack(false);
	};

	const onEndChat = async () => {
		Cookies.remove('kodifConversationId' + appID);
		if (isActiveChat) {
			// await stompClient.deactivate();
			await stompClient.disconnect();
		}
		setIsCloseMenuActive(false);
		onExpandChange(false);
		setOpenSnack(false);
		setChatMessages([]);
	};

	const onNewChat = async () => {
		if (isQuickReplyActive) {
			setQuickReplies([]);
		}
		setIsNewChat(true);
		setDisabledInputBox(true);
		setOpenSnack(false);
		await stompClient.disconnect();
		await stompClient.deactivate();
		setIsCloseMenuActive(false);
		setChatMessages([]);
		setQuickReplies([]);
		// setChatBotReplyTimeout({ active: false, maxTimeout: 30 });
		setTimeout(async () => {
			await startConversationData();
		}, 500);
	};

	const renderChatMessages = () => {
		let isTimeEnabled = true;
		return chatMessages.map((message, i) => {
			const nextMessageType = chatMessages.length > i + 1 ? chatMessages[i + 1].type : null;
			isTimeEnabled = nextMessageType !== message.type;
			return (
				<ChatMessage
					appID={appID}
					conversationId={conversationId}
					companyConfig={companyConfig}
					key={`ChatMessage-${i}`}
					message={message}
					isTimeEnabled={isTimeEnabled}
					playground={playground}
				/>
			);
		});
	};

	let classNameBubble = classes.quickRepliesBoxContainer;

	if (quickReplies?.length > 3) {
		classNameBubble = classes.quickRepliesBoxContainerMini;
	}

	if (playground) {
		// eslint-disable-next-line no-undef
		require('../assets/css/chat.module.css');
	}

	function FooterBlock() {
		if (playground) {
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<PoweredByKodifLogo />
				</div>
			);
		}
		return <PoweredByKodifLogo />;
	}

	if (!isActiveChat) {

		// if (isActiveChat && !playground) {
		return (
			<div
				className={`${
					playground ? classes.chatContainerPlayground : isMobile ? classes.chatContainerMobile : classes.chatContainer
				}`}
				style={{
					background: '#FFFFFF',
					margin: `${appPosition === 'right' ? '0 0 0 auto' : '0 auto 0 0'}`,
					boxShadow: `boxShadow: '0px 5px 31px ${companyConfig?.brandColor}`,
				}}
			>
				<div className={classes.chatHeader} style={{ display: extension && 'none' }}>
					<div className={classes.chartHeaderLogoContainer}>
						<img
							src={companyConfig?.chatLogo}
							alt="logo"
							style={{ maxWidth: 170, maxHeight: 45, overflow: 'hidden' }}
						/>
						{!_.isEmpty(companyConfig?.name) && (
							<Typography
								marginLeft="10px"
								marginTop="5px"
								color={companyConfig.headerTitleColor || 'white'}
								lineHeight="normal"
							>
								{companyConfig.name}
							</Typography>
						)}
					</div>
					{!playground && (
						<div className={classes.chartHeaderLogoContainer}>
							<CloseButton fill={companyConfig?.backgroundColor} onClick={() => setIsCloseMenuActive(true)} />
						</div>
					)}
				</div>
				<div className={classes.chatContentContainer} style={{ height: extension && '100%', boxShadow: 'none' }}>
					<div
						className={classes.chatLoading}
						style={{
							background: companyConfig.brandColor
						}}
					></div>
				</div>
				<ChatInputBox
					onUserMessageSubmit={onUserMessageSubmit}
					debouncedOnUserMessageChange={debouncedOnUserMessageChange}
					textInputRef={textInputRef}
					fileUploadRef={fileUploadRef}
					submitType={submitType}
					setSubmitType={setSubmitType}
					setUserUpdFile={setUserUpdFile}
					showUploadButton={showUploadButton}
					disableWindowBlurListener
					isNewChat={isNewChat}
					disabledInputBox={disabledInputBox}
					playground={playground}
					companyConfig={companyConfig}
					setUserMessage={setUserMessage}
					userMessage={userMessage}
					userFileMessage={userFileMessage}
					setUserFileMessage={setUserFileMessage}
					onUserFileSubmit={onUserFileSubmit}
				/>
				<div className={classes.chatBoxFooter} style={{ display: extension && 'none' }}>
					<FooterBlock />
				</div>
				{isCloseMenuActive && (
					<ChatCloseConfirmation
						companyConfig={companyConfig}
						fill={companyConfig?.headerIconColor || 'white'}
						onCancel={() => setIsCloseMenuActive(false)}
						onEndChat={onEndChat}
					/>
				)}
			</div>
		);
	}

	const showHideTextBox = () => {
		if (!openSnack) {
			if (isActiveTextInput === null && !isQuickReplyActive) {
				return (
					<ChatInputBox
						companyConfig={companyConfig}
						textInputRef={textInputRef}
						onUserMessageSubmit={onUserMessageSubmit}
						debouncedOnUserMessageChange={debouncedOnUserMessageChange}
						fileUploadRef={fileUploadRef}
						submitType={submitType}
						setSubmitType={setSubmitType}
						setUserUpdFile={setUserUpdFile}
						showUploadButton={showUploadButton}
						isNewChat={isNewChat}
						disabledInputBox={disabledInputBox}
						playground={playground}
						setUserMessage={setUserMessage}
						userMessage={userMessage}
						userFileMessage={userFileMessage}
						setUserFileMessage={setUserFileMessage}
						onUserFileSubmit={onUserFileSubmit}
					/>
				);
			}
			if (isActiveTextInput) {
				return (
					<ChatInputBox
						sessionText={sessionText}
						companyConfig={companyConfig}
						onUserMessageSubmit={onUserMessageSubmit}
						debouncedOnUserMessageChange={debouncedOnUserMessageChange}
						textInputRef={textInputRef}
						fileUploadRef={fileUploadRef}
						submitType={submitType}
						setSubmitType={setSubmitType}
						setUserUpdFile={setUserUpdFile}
						showUploadButton={showUploadButton}
						isNewChat={isNewChat}
						disabledInputBox={disabledInputBox}
						playground={playground}
						setUserMessage={setUserMessage}
						userMessage={userMessage}
						userFileMessage={userFileMessage}
						setUserFileMessage={setUserFileMessage}
						onUserFileSubmit={onUserFileSubmit}
					/>
				);
			}
			return null;
		}
		return null;
	};
	const handleClick = () => {
		if (!isNewChat) {
			onNewChat();
		}
	};

	return (
		<div
			className={`${
				playground ? classes.chatContainerPlayground : isMobile ? classes.chatContainerMobile : classes.chatContainer
			}`}
			style={{
				background: companyConfig.brandColor,
				margin: `${appPosition === 'right' ? '0 0 0 auto' : '0 auto 0 0'}`,
				maxHeight: playground ? '100%' : isMobile ? maxHeight : maxHeight - 130,
			}}
		>
			{!hideHeader && (
				<div className={classes.chatHeader} style={{ display: extension && 'none' }}>
					<div className={classes.chartHeaderLogoContainer}>
						<img
							src={companyConfig?.chatLogo}
							alt="logo"
							style={{ maxWidth: 170, maxHeight: 45, overflow: 'hidden' }}
						/>
						{!_.isEmpty(companyConfig?.name) && (
							<Typography
								marginLeft="10px"
								marginTop="5px"
								color={companyConfig.headerTitleColor || 'white'}
								lineHeight="normal"
							>
								{companyConfig.name}
							</Typography>
						)}
					</div>
					<Tooltip
						title="Start New Conversation"
						sx={{
							top: 0
						}}
					>
						<button
							onClick={handleClick}
							onTouchStart={handleClick} // Ensure touch events are handled
							className={classes.chartHeaderRefreshIcon}
							style={{ opacity: isNewChat ? 0.5 : 1 }}
						>
							<RefreshIcon width={20} fill={companyConfig?.headerIconColor || 'white'} />
						</button>
						</Tooltip>
					{!playground && (
						<div className={classes.chartHeaderMinimize} onClick={() => onExpandChange(false)}>
							<MinimizeBtn fill={companyConfig?.headerIconColor || 'white'} />
						</div>
					)}
				</div>
			)}
			<div
				className={playground ? classes.chatContentContainerPlayground : classes.chatContentContainer}
				style={{
					height: containerHeight,
					display: 'flex', // Maintain flex display
					borderRadius: isMobile || playground ? '0' : '0 0 16px 16px'
				}}
			>
				{isNewChat && (
					<div
						className={classes.chatLoading}
						style={{
							background: companyConfig.brandColor
						}}
					></div>
				)}
				{!isNewChat && (
					<>
						<div
							className={playground ? classes.chatContentPlayground : classes.chatContent}
							ref={chatWindowRef}
							// style={{ height: `${521 - chatOffsetHeight}px` }}
						>
							{renderChatMessages()}
							{showPreviewImg && (
								<AlertFile
									setShowPreviewImg={setShowPreviewImg}
									fileUploadRef={fileUploadRef}
									fileImg={userUpdFile}
									fileUrl={fileUploadRef.current.value}
								/>
							)}
							{isChatBotMessage && isTyping && (
								<ChatMessageTyping companyConfig={companyConfig} playground={playground} />
							)}
							{/* {userFileMessage === 'Loading' && <LinearProgress sx={{ marginY: '10px' }} />}*/}
							{!_.isEmpty(userFileMessage) && userFileMessage !== 'Loading' && (
								<Alert severity="warning" icon={false} className={classes.fileMessageInfo}>
									{userFileMessage}
								</Alert>
							)}

							{/*          {chatBotReplyTimeout.error && (*/}
							{/*              <div className={classes.errorMessage}>*/}
							{/* Sorry something went wrong. Please restart the chat*/}
							{/*                  <Tooltip*/}
							{/*                      title="Start New Conversation"*/}
							{/*                      sx={{*/}
							{/*                          top: 0,*/}
							{/*                      }}*/}
							{/*                  >*/}
							{/*                      <button*/}
							{/*                          disabled={isNewChat}*/}
							{/*                          className={classes.chartHeaderRefreshIcon}*/}
							{/*                          style={{ opacity: isNewChat ? 0.5 : 1 }}*/}
							{/*                      >*/}
							{/*                          <RefreshIcon*/}
							{/*                              onClick={onNewChat}*/}
							{/*                              width={20}*/}
							{/*                              sx={{*/}
							{/*                                  color: 'white',*/}
							{/*                              }}*/}
							{/*                          />*/}
							{/*                      </button>*/}
							{/*                  </Tooltip>*/}
							{/*              </div>*/}
							{/*          )}*/}
							{isQuickReplyActive && !isTyping && (
								<div ref={quickRepliesContainerRef} className={classNameBubble} style={{ width: '100%' }}>
									{quickReplies.map((reply, i) => (
										<ChatQuickReply
											companyConfig={companyConfig}
											quickRepliesCount={quickReplies?.length || 0}
											key={`reply-${i}`}
											reply={reply}
											onQuickReplyClick={onQuickReplyClick}
										/>
									))}
								</div>
							)}
						</div>
					</>
				)}
				{showHideTextBox()}
				{!hideFooter && (
					<div
						className={playground ? classes.chatBoxFooterPlayground : classes.chatBoxFooter}
						style={{ display: extension && 'none' }}
					>
						<FooterBlock />
					</div>
				)}
				{openSnack && (
					<ChatCSAT
						companyConfig={companyConfig}
						onNewChat={onNewChat}
						sessionText={sessionText}
						handleCloseDialog={handleCloseDialog}
						onEndChat={onEndChat}
					/>
				)}
			</div>
		</div>
	);
}
