import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(_ => ({
	closeMenuOverlay: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		left: '0px',
		top: '0px',
		background: 'rgba(0, 0, 0, 0.22)',
		borderRadius: '16px',
		zIndex: 1500
	},
	closeMenuContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '8px 0px',
		gap: '20px',
		position: 'absolute',
		width: '100%',
		height: '186px',
		left: '0',
		bottom: '0',
		background: '#FFFFFF',
		boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.2)',
		borderRadius: '8px'
	},
	closeButtonContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		padding: '0px',
		gap: '5px',
		width: '310px',
		height: '24px',
		flex: 'none',
		order: '0',
		flexGrow: '0',
		float: 'right'
	},
	closeMenuButton: {
		cursor: 'pointer',
		filter: 'invert(58%) sepia(6%) saturate(1210%) hue-rotate(179deg) brightness(89%) contrast(85%)'
	},
	closeMenuMessage: {
		width: '262px',
		height: '18px',
		fontFamily: "'Inter'",
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '24px',
		alignItems: 'center',
		color: '#555F6D',
		margin: '0'
	},
	closeMenuButtonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0px',
		gap: '12px',
		width: '364px',
		height: '88px',
		flex: 'none',
		order: '2',
		flexGrow: '0'
	},
	menuButton: {
		fontFamily: "'Inter'",
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '14px',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0px',
		gap: '6px',
		width: '364px',
		height: '38px',
		borderRadius: '7px',
		flex: 'none',
		flexGrow: '0'
	},
	endChatBtn: {
		color: 'white',
		background: '#C53434',
		order: '0',
		borderRadius: '12px',
		'@media only screen and (max-width: 768px)': {
			// Adjust width for smaller screens like phone
			width: '320px'
		}
	},
	cancelCloseBtn: {
		order: '1'
	}
}));

export default function ChatCloseConfirmation({ onCancel, onEndChat, companyConfig }) {
	const classes = useStyles();
	return (
		<div className={classes.closeMenuOverlay}>
			<div className={classes.closeMenuContainer}>
				<div className={classes.closeButtonContainer}></div>
				<p className={classes.closeMenuMessage}>Are you sure you want to end this chat?</p>
				<div className={classes.closeMenuButtonGroup}>
					<div className={`${classes.menuButton} ${classes.endChatBtn}`} onClick={onEndChat}>
						End Chat
					</div>
					<div className={`${classes.menuButton} ${classes.cancelCloseBtn}`} onClick={onCancel}>
						Cancel
					</div>
				</div>
			</div>
		</div>
	);
}
