import React from 'react';

const CloseButton = ({ width = "16", height = "16", fill = 'white', ...props }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 15 14" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L7.5 5.93934L12.9697 0.46967C13.2626 0.176777 13.7374 0.176777 14.0303 0.46967C14.3232 0.762563 14.3232 1.23744 14.0303 1.53033L8.56066 7L14.0303 12.4697C14.3232 12.7626 14.3232 13.2374 14.0303 13.5303C13.7374 13.8232 13.2626 13.8232 12.9697 13.5303L7.5 8.06066L2.03033 13.5303C1.73744 13.8232 1.26256 13.8232 0.96967 13.5303C0.676777 13.2374 0.676777 12.7626 0.96967 12.4697L6.43934 7L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z" 
        fill={fill}
      />
    </svg>
  );
};

export default CloseButton