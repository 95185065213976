import {Alert, Box, Avatar} from "@mui/material";
import {searchWordInTypesForAlert} from "../../utils";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import React from "react";


const LOCAL_IMG_TYPES = [
    'image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'svg', 'gif', 'jpeg', 'jpg', 'png', 'svg'
];

export default function ({ fileUploadRef, fileUrl, fileImg, setShowPreviewImg }) {
    return (
        <Box width="75%" marginLeft="auto">
            <Alert
                icon={
                    searchWordInTypesForAlert(fileUrl, LOCAL_IMG_TYPES) ?
                        <Avatar src={fileImg} alt="attach" />
                        : <InsertDriveFileIcon />
                }
                title={fileUrl.substring(10)}
                onClose={() => {
                    fileUploadRef.current.value = "";
                    setShowPreviewImg(false);
                }}
                sx={{
                    color: 'black',
                    background: '#dedede',
                    '& .MuiAlert-icon' : {
                        color: 'black',
                    }
                }}
            >
                {fileUrl.substring(12)}
            </Alert>
        </Box>
    )
}