import React, { useState, useRef, useEffect } from 'react';
import SendLogo from '../assets/img/send-logo.svg';
import { makeStyles } from '@mui/styles';
import { IconButton, Box, Popper, Fade, useMediaQuery } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { ClickAwayListener } from '@mui/base';
import EmojiPicker from 'emoji-picker-react';
import { convertBase64, FILE_TYPES, FILE_SIZES, LOCAL_FILE_ACCEPT } from '../utils';

const useStyles = makeStyles(_ => ({
	chatBoxContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '12px',
		position: 'relative',
		width: '100%',
		boxSizing: 'border-box'
	},
	chatBox: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '14px 10px',
		width: '100%',
		height: '56px', // Set the minimum height
		maxHeight: '400px', // Set the maximum height
		border: '1px solid #CFD6DD',
		borderRadius: '12px',
		flex: 'auto',
		marginRight: '5px',
		marginLeft: '5px',
		outline: 'none',
		resize: 'none', // Disable manual resize by the user
		overflow: 'auto', // To handle overflow
		'&:focus-visible': {
			border: '1px solid #CFD6DD', // Set border width and color on focus
				}
	},
	submitBtn: {
		background: 'none',
		borderRadius: '50%',
		width: 46,
		height: 46,
		flex: '0 0 46px',
		color: 'inherit',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: 'none',
		padding: '0',
		font: 'inherit',
		cursor: 'pointer',
		outline: 'inherit'
	},
	emojiSize: {
		fontSize: '18px'
	}
}));

const styleContainer = {
	pointerEvents: 'none'
};

export default function ChatInputBox({
	onUserMessageSubmit,
	textInputRef,
	fileUploadRef,
	submitType,
	setSubmitType,
	setUserUpdFile,
	showUploadButton,
	disabledInputBox,
	companyConfig,
	setUserMessage,
	userMessage,
	setUserFileMessage,
	onUserFileSubmit
}) {
	const classes = useStyles();
	const mdUp = useMediaQuery('(min-width:350px)');
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const canBeOpen = open && Boolean(anchorEl);
	const id = canBeOpen ? 'transition-popper' : undefined;

	useEffect(() => {
		// This makes the input box focused when the chat widget is opened or after receiving a message
		if (!disabledInputBox) {
			textInputRef?.current?.focus();
		}
	}, [disabledInputBox, textInputRef]);

	const handleClickPopover = event => {
		setAnchorEl(event.currentTarget);
		setOpen(previousOpen => !previousOpen);
	};

	const handleClickAway = () => {
		setOpen(false);
	};

	function onClickEmoji(emojiData) {
		setUserMessage(prevState => prevState + emojiData.emoji);
	}

	function checkMessageType() {
		if (submitType === 'text') {
			onUserMessageSubmit();
		}
		if (submitType === 'attachment') {
			onUserFileSubmit();
		}
	}

	const handleKeyDown = e => {
		if (e.key === 'Enter') {
			checkMessageType();
		}
	};

	const handleFileSelect = async event => {
		try {
			const targetFile = event.target.files[0];
			if (!FILE_TYPES(targetFile.type)) {
				setUserFileMessage('Not a valid file type. Update your selection.');
				setTimeout(() => setUserFileMessage(''), 4000);
				return;
			}
			if (!FILE_SIZES(targetFile.size)) {
				setUserFileMessage('File too Big, please select a file less than 10mb.');
				setTimeout(() => setUserFileMessage(''), 4000);
				return;
			}

			const base64 = await convertBase64(targetFile);
			setSubmitType('attachment');
			setUserUpdFile(base64);
			// event.target.reset();
			// onUserFileSubmit(base64, userMessage);
		} catch (error) {
			setUserFileMessage('');
			// event.target.reset();
			console.warn(error);
		}
	};

	return (
		<div className={classes.chatBoxContainer} style={disabledInputBox ? styleContainer : {}}>
			{mdUp && (
				<>
					{showUploadButton && (
						<IconButton
							sx={{ transform: 'rotate(45deg)' }}
							aria-label="upload"
							size="small"
							onClick={() => fileUploadRef?.current?.click()}
						>
							<AttachFileIcon fontSize="small" />
							<input ref={fileUploadRef} hidden accept={LOCAL_FILE_ACCEPT} type="file" onChange={handleFileSelect} />
						</IconButton>
					)}
				</>
			)}
			<textarea
				value={userMessage}
				disabled={disabledInputBox}
				className={classes.chatBox}
				type="text"
				name="chat-box"
				placeholder={companyConfig?.textInputPlaceholder ?? 'Message...'}
				onChange={e => setUserMessage(e.target.value)}
				ref={textInputRef}
				onKeyDown={handleKeyDown}
				onTouchStart={e => {
					setTimeout(() => {
						e?.target?.scrollIntoView(false);
					}, 200);
				}}
				style={{
					fontFamily: 'proximanova-regular, Inter, sans-serif',
					fontSize: '14px',
					fontWeight: '450'
				}}
			/>
			<div
				className={classes.submitBtn}
				onClick={e => {
					e.preventDefault();
					checkMessageType();
				}}
				style={{
					display: userMessage ? 'flex' : 'none',
					background: companyConfig?.secondaryColor ? companyConfig.secondaryColor : companyConfig.chatOpenCloseColor
				}}
			>
				<img src={SendLogo} alt="send-logo" />
			</div>
		</div>
	);
}
